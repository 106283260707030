import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Publications from '../components/publications/Publications';

const PublicationsPage = () => (
  <Layout>
    <SEO
      title='Publications | MIT Biomimetic Robotics Lab'
      description='Publications page for the MIT Biomimetic Robotics Lab'
    />
    <Publications />
  </Layout>
);

export default PublicationsPage;

import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';

import { Publication } from '../../types';
import { screenWidthSettings } from '../../constants';
import FadeInSection from '../FadeInSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
      width: '95vw',
      maxWidth: screenWidthSettings.largeLaptopScreenSize,
      margin: '15px 0 15px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      border: '1px solid black',
      boxShadow: '0 1px 5px 0px rgba(38,59,94,0.3)',
      '&:hover,&:focus-within': {
        boxShadow: '0 2px 15px 5px rgba(38,59,94,0.3)',
        transition: '0.25s ease-out',
      },
    },
    articleTitle: {
      position: 'relative',
      fontWeight: 'bold',
      color: 'inherit',
      marginBottom: '5px',
      '&:focus-within': {
        border: 'none',
      },
    },
    underlineOnHover: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    description: {
      fontWeight: 'lighter',
    },
  })
);

const PublicationCard = ({
  publication,
}: {
  publication: Publication;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <FadeInSection>
      <Card className={classes.root}>
        <Typography
          classes={{ root: classes.articleTitle }}
          variant='h6'
          component={Link}
          to={`/publications/` + publication.ID}
        >
          <span className={classes.underlineOnHover}>{publication.Title}</span>
        </Typography>
        <Typography
          className={classes.description}
          variant='body2'
          component='p'
        >
          {publication.Citation}
        </Typography>
      </Card>
    </FadeInSection>
  );
};

export default PublicationCard;

/* This text will be displayed below in the header of the publications page */

import { PageHeader } from '../../types';
import photo from './cover_photo.jpg';

const header: PageHeader = {
  coverPhoto: photo,
  headerStatement: `"Insufficient data for a meaningful answer." - Isaac Asimov`,
};

export default header;

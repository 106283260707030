import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import header from '../../assets/publications/header';
import { colorPalette, screenWidthSettings } from '../../constants';
import { Publication, PublicationsList, State } from '../../types';
import PageHeader from '../PageHeader';
import ScrollUpButton from '../ScrollUpButton';
import PublicationCard from './PublicationCard';
import PublicationsRefineResults from './PublicationsRefineResults';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'black',
      minHeight: '100vh',
      width: '100vw',
      maxWidth: screenWidthSettings.maxScreenWidth,
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
    contentContainer: {
      overflow: 'hidden', //prevents scrollbar from appearing when card animates
      minHeight: '100vh',
      width: '100%',
      maxWidth: screenWidthSettings.maxScreenWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
  })
);

const Publications = () => {
  const classes = useStyles();
  const displayedPublications = useSelector(
    (state: State): PublicationsList => state.publicationsReducer.displayList
  );

  return (
    <Container className={classes.root} component='section'>
      <PageHeader
        title='Publications'
        coverPhoto={header.coverPhoto}
        headerStatement={header.headerStatement}
        dividerColor={colorPalette.green}
        scrollToElementID='publications-refine-results'
      />
      <Container className={classes.contentContainer}>
        <PublicationsRefineResults />
        {displayedPublications.map(
          (publication: Publication): JSX.Element => {
            return (
              <PublicationCard publication={publication} key={publication.ID} />
            );
          }
        )}
      </Container>
      <ScrollUpButton arrowColor={colorPalette.green} />
    </Container>
  );
};

export default Publications;

import { publicationsList } from './publicationsList';
import { FilterOptions, Publication } from '../../types';

// go thru all publications and generate a list of possible options for each filter drop down
const year: string[] = [];
const publicationType: string[] = [];
publicationsList.forEach((item: Publication) => {
  const yearPub = item['Year of Publication'] as string;
  const yearConf = item['Year of Conference'] as string;
  const pub = item['Publication Type'];
  if (yearPub && year.indexOf(yearPub) === -1) {
    year.push(yearPub);
  }
  if (yearConf && year.indexOf(yearConf) === -1) {
    year.push(yearConf);
  }
  if (pub && publicationType.indexOf(pub) === -1) {
    publicationType.push(pub);
  }
});
year.sort().reverse();
publicationType.sort();
year.unshift('All');
publicationType.unshift('All');

export const filterOptions: FilterOptions = {
  Year: year,
  'Publication Type': publicationType,
};
